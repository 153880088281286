



























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { isMobile } from 'mobile-device-detect';
import ScrollArea from './ScrollArea.vue';

@Component({        
})
export default class Window extends Vue {


    @Prop({ type:Boolean, default: false }) readonly goBack!: boolean
    @Prop({ type:Boolean, default: false }) readonly isBlackout!: boolean
    @Prop({ type:String , default: "/"   }) readonly backPath!: string
    @Prop({ type:Boolean , default: false   }) readonly noCloseButton!: boolean

    onClickClose() {
        this.$emit("close");
        if(this.backPath && this.backPath.length)
            this.$router.push( this.localePath(this.backPath));

        if(this.goBack)
            this.$router.go(-1)
    }
    
    updateScrollStatus() {
        (<ScrollArea>this.$refs.scrollArea).updateScrollStatus();
    }
}
