import { render, staticRenderFns } from "./ButtonRed.vue?vue&type=template&id=c215aa62&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BgButton: require('/artifacts/BGFrontend/components/Ui/BgButton.vue').default})
