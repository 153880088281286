import { render, staticRenderFns } from "./BgButton.vue?vue&type=template&id=6fd17c13&scoped=true&"
import script from "./BgButton.vue?vue&type=script&lang=ts&"
export * from "./BgButton.vue?vue&type=script&lang=ts&"
import style0 from "./BgButton.vue?vue&type=style&index=0&id=6fd17c13&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd17c13",
  null
  
)

export default component.exports