












import { mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPlayerStatistics } from '~/modules/model/Types/IPlayerStatistics'
import Player from '~/modules/model/Types/Player'

@Component({})
export default class PlayerStats extends Vue {
    @Prop(String) uuid !: string    
    player: Player | null = null
    statistics: IPlayerStatistics | null = null
    
    async created() {
        this.player = await this.$store.dispatch("doViewPlayer", this.uuid);
    }

    async beforeMount() {     
        this.statistics = await this.$store.dispatch( "getStatistics", this.uuid )       
    }
}
