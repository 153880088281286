











import { Vue, Component, Prop } from 'vue-property-decorator'
import Player from '~/modules/model/Types/Player'
import { EFlagColor } from '~/modules/model/Types/IPlayerSettings'

@Component({})
export default class PlayerStag extends Vue {
    @Prop(Object) player !: Player
    @Prop({ default : false }) king !: boolean    
    @Prop({ default : null }) flagColor?: EFlagColor | null
    @Prop({ default : true }) showRating?: boolean
    @Prop({ default : false }) showPipe?: boolean
    
    get name() { return this.player ? this.player.name : '' }
    get rating() { return this.player ? this.player.rating : '' }

    get color() { 
        if(this.flagColor)
            return this.flagColor;

        let color = this.player && this.player.settings && this.player.settings.flag_color ? this.player.settings.flag_color : EFlagColor.ANY
        if(color == EFlagColor.ANY)
            color = EFlagColor.RED
        return color;        
    }
}
