import { render, staticRenderFns } from "./GameInfo.vue?vue&type=template&id=5a63963c&scoped=true&lang=pug&"
import script from "./GameInfo.vue?vue&type=script&lang=ts&"
export * from "./GameInfo.vue?vue&type=script&lang=ts&"
import style0 from "./GameInfo.vue?vue&type=style&index=0&id=5a63963c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a63963c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Avatar: require('/artifacts/BGFrontend/components/Ui/Avatar.vue').default,Window: require('/artifacts/BGFrontend/components/Window.vue').default})
