








import { Vue, Component, Prop } from 'vue-property-decorator'
import Player from '~/modules/model/Types/Player'
import { EDialogEvents } from '../Dialogs.vue'

@Component({})
export default class ActiveGameLabel extends Vue {
    @Prop(Object) player !: Player

    get type_id() {
        return this.player && this.player.avatarCode != undefined ? this.player.avatarCode : 0
    }
    get custom_uri() {
        return this.player ? this.player.avatarUri : ''
    }
    get isCustom(){
        //avatarCode might not even be there at all:
        return this.type_id == 999
    }
    get customUriStyle(){
        if ( this.isCustom )
            if (this.custom_uri && this.custom_uri !== undefined) {
                //пока не будем использовать кастомные аватарки
                return { backgroundImage: 'url(' + require('@/assets/pictures/avatars/avatar_0.png') + ')' }//{'background-image' : `url(${this.custom_uri})`}
            }
            else {
                return { backgroundImage: 'url(' + require('@/assets/pictures/avatars/avatar_0.png') + ')' }
            }
        return  { backgroundImage: 'url(' + require('@/assets/pictures/avatars/avatar_'+this.type_id.toString()+'.png') + ')' }
    }
    goPlayer() {
        this.$root.$emit(EDialogEvents.SHOW_PLAYER_INFO, this.player.uuid);        
    }
}
