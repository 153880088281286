// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/ui/buttons/ng_btn_42.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/ui/themes/dark/borders/ng_border_6.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title[data-v-37f8b6fc]{border-bottom:.0714em solid #ccc}.header[data-v-37f8b6fc],.title[data-v-37f8b6fc]{text-transform:uppercase;font-size:1.7em;line-height:1em;padding:.5em 0;font-weight:700}.footer[data-v-37f8b6fc],.header[data-v-37f8b6fc],.title[data-v-37f8b6fc]{color:#eed9a0;text-align:center;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.footer[data-v-37f8b6fc]{bottom:0;border-top:.0714em solid #ccc}.close_button[data-v-37f8b6fc]{position:absolute;top:0;right:.5em;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:3.1428em;height:3.1428em;line-height:3.1428em;padding:0}.mobile_window[data-v-37f8b6fc]{width:70%;height:100%;background-color:rgba(0,0,0,.8);background-repeat:repeat;background-position:top;position:relative;text-align:left;box-sizing:border-box;cursor:default;max-width:100%;max-height:100%;border-image-source:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");border-image-slice:0 42 0 42 fill;border-image-width:0 1.5em 0 1.5em;border-image-outset:0 1.3571em 0 1.5em;border-image-repeat:repeat repeat;margin:0 auto;min-height:5em;min-width:5em}.aspect_ratio_box:not(.device_mobile) .mobile_window[data-v-37f8b6fc]{width:43.75%}.blackout[data-v-37f8b6fc]{background-color:rgba(0,0,0,.95)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
