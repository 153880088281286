





































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPlayerStatistics } from '~/modules/model/Types/IPlayerStatistics'
import { mapGetters, mapState } from 'vuex'

@Component({})
export default class WindowStatisticGeneral extends Vue {     
    @Prop(Object) statistics !: IPlayerStatistics

    get credits_won() { return this.statistics ? this.statistics.credits.win : '' }
    get credits_lost() { return this.statistics ? this.statistics.credits.loose : '' }
    get dice_rolls() { return this.statistics ? this.statistics.dice.count : '' }
    get dice_drops() { return this.statistics ? this.statistics.dice.sum : '' }
    get duplicates_dropped() { return this.statistics ? this.statistics.dice.double_count : '' }

    get dropped_value_1() { return this.statistics ? this.statistics.dice.double_counts[ 0 ] : '' }
    get dropped_value_2() { return this.statistics ? this.statistics.dice.double_counts[ 1 ] : '' }
    get dropped_value_3() { return this.statistics ? this.statistics.dice.double_counts[ 2 ] : '' }
    get dropped_value_4() { return this.statistics ? this.statistics.dice.double_counts[ 3 ] : '' }
    get dropped_value_5() { return this.statistics ? this.statistics.dice.double_counts[ 4 ] : '' }
    get dropped_value_6() { return this.statistics ? this.statistics.dice.double_counts[ 5 ] : '' }

    get parity_games() { return this.statistics ? this.statistics.game.parity : '' }
    get games_won() { return this.statistics ? this.statistics.game.win : '' }
    get games_lost() { return this.statistics ? this.statistics.game.loose : '' }

    get rating() { return this.statistics ? (this.statistics.rating.value ? this.statistics.rating.value : '0') : '' }

    get tournaments_won() { return this.statistics ? this.statistics.tournament.win : '' }
    get tournaments_lost() { return this.statistics ? this.statistics.tournament.loose : '' }
}
