































































































import Vue from 'vue'
import Game, { IGame, EGameType, EGameKind, EGameSpeed } from '~/modules/model/Types/Game';
import { print_timestamp } from '~/modules/utility/utils';

export default Vue.extend({
    props : [
        'gameUuid',
    ],
    data : ()=>({
        game : {} as Game,
    }),
    async created() {        
        if( ! this.gameUuid || ! this.gameUuid.length )
            return;
        
        const igame : IGame = await this.$store.dispatch( 'game/doViewGame', this.gameUuid )
        this.game = new Game( igame )
        console.log( 'GAME:', this.game )
    },
    methods : {
        type() : string {
            if ( ! this.game ) return 'undefined'
            switch ( this.game.type_id ) {
                case EGameType.TRAIN:
                    return this.$tc('Training')
                case EGameType.CUSTOM_DUEL:
                    return this.$tc('Custom duel')
                case EGameType.CUSTOM_MATCH:
                    return this.$tc('Custom match')
                case EGameType.TOURNAMENT:
                    return this.$tc('Game_type.tournament')
                default:
                    return 'undefined'
            }
        },
        backgammon() : string {
            if ( ! this.game ) return 'undefined'
            switch ( this.game.game_type_id ) {
                case EGameKind.LONG:
                    return this.$tc('Long backgammon')
                case EGameKind.SHORT:
                    return this.$tc('Short backgammon')
                default:
                    return 'undefined'
            }
        },
        speed() {
            return this.game ? this.game.gameSpeedTextId : undefined
        },
        time_to_turn() {
            if (this.game && this.game.rules && this.game.rules.timeLimit && this.game.rules.timeLimit.step) {
                let step = this.game.rules.timeLimit.step;
                if (step < 60) {
                    return step + this.$tc('Turn.sec')
                }
                let sec = step % 60;
                let min = Math.floor(step / 60);
                let res = min + this.$tc('Turn.min');
                if (sec > 0) {
                    res += ' ' +  sec + this.$tc('Turn.sec')
                }
                return res;
            }
            else {
                return undefined
            }
        },
        name_1() {
            if ( ! this.game || ! this.game.playerA ) return ''
            return this.game.playerA.name
        },
        name_2() {
            if ( ! this.game || ! this.game.playerB ) return ''
            return this.game.playerB.name
        },
        double_cube() {
            return this.game && this.game.rules && this.game.rules.doubleCube.enabled ? this.$tc('Yes') : this.$tc('No')
        },
        cube_auto() {
            return this.game && this.game.rules && this.game.rules.doubleCube.autoDouble.enabled ? this.$tc('Yes') : this.$tc('No')
        },
        cube_auto_limit() {
            return this.game && this.game.rules && this.game.rules.doubleCube.autoDouble.limit
        },
        biver() {
            return this.game && this.game.rules && this.game.rules.doubleCube.biver ? this.$tc('Yes') : this.$tc('No')
        },
        paritet() {
            return this.game && this.game.rules && this.game.rules.paritet && this.game.rules.paritet.enabled ? this.$tc('Yes') : this.$tc('No')
        },
        mars() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.mars ? this.$tc('Yes') : this.$tc('No')
        },
        koks() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.koks ? this.$tc('Yes') : this.$tc('No')
        },
        jakobi() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.jakobi ? this.$tc('Yes') : this.$tc('No')
        },
        crawford() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.crawford ? this.$tc('Yes') : this.$tc('No')
        },
        dateCreateInCurrentTimeZone(h: Game) : string {
            if (h && h.created_timestamp) {
                return print_timestamp(h.created_timestamp)
            }
            else {
                return '';
            }
        },
       gameCaption() {
            if (this.game) {
                let caption = this.game.caption;
                if (caption.indexOf('{Round #}') > -1) {
                    caption = caption.replace(/{Round #}/, this.$tc('Tournament.Tour') + ' ');
                }
                return caption;
            }
            return '';
        }
    },
})
