// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ui/ng_preloader.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".preloader[data-v-41c2f7cb]{width:8.5714em;height:8.5714em;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:transparent;background-repeat:no-repeat;background-position:0;background-size:auto 8.5714em;-webkit-animation:ng_preloader-data-v-41c2f7cb 1.5s steps(18) infinite;animation:ng_preloader-data-v-41c2f7cb 1.5s steps(18) infinite}@-webkit-keyframes ng_preloader-data-v-41c2f7cb{to{background-position:100%}}@keyframes ng_preloader-data-v-41c2f7cb{to{background-position:100%}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
