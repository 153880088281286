













//

import Vue from 'vue'
export default Vue.extend({
    props:{
        styleId:{            
            
        },
        disabled : { type : Boolean, default : false },
    },
    methods:{
        click() {            
            this.$emit("click")
        }
    }

})
