








import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Player from '~/modules/model/Types/Player'

@Component({})
export default class PlayerLabel extends Vue {
    @Prop(Object) player !: Player

    get name(){
        return this.player ? this.player.name : ''
    }
}
